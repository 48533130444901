import isEqual from 'lodash/isEqual';
import ComponentStore, { ComponentStoreHelpers } from 'components/data/ComponentStore';
import User from 'components/data/User';
import Setup from 'components/data/Setup';
import { FileType } from 'components/bricks/types/brick.type';
import AssetHelper, { AUDIO_EXTENSIONS, IMAGE_EXTENSIONS, VIDEO_EXTENSIONS } from 'helpers/asset.helper';
import AssetGalleryDialogState, { AssetGalleryDialogContentViewType } from '../interfaces/AssetGalleryDialogState';
import AssetGalleryDialogProps from '../interfaces/AssetGalleryDialogProps';
import { DIALOG_TITLES } from '..';
import { defaultSelectorTypes } from '../components/content/sidebar/data/sidebar-selectors';
import AssetGalleryDialogSelectorType from '../interfaces/AssetGalleryDialogSelectorType';
import AGDPropsToStateHelper from './asset-gallery-dialog-props-to-state-helper';

/**
 * This class contains helper functions for the Asset Gallery Dialog component.
 */
class AssetGalleryDialogHelper {
    /**
     * Handles the content space token by fetching it if it doesn't exist and setting the filterExpired flag to true if it's not already set.
     */
    static handleContentSpaceToken = async (
        canUseContentSpace: boolean,
        contentSpaceToken: string,
        fetchToken: () => void,
        setFilterExpired: (filterExpired: boolean) => void,
        ignoreFilterExpired: boolean,
        filterExpired: boolean
    ): Promise<void> => {
        if (canUseContentSpace) {
            if (!contentSpaceToken) await fetchToken();
            // If filterExpired is false, set it to true and reset the Redux store. We  want to filter out expired assets in campaigns.
            if (!ignoreFilterExpired && !filterExpired) setFilterExpired(true);
        }
    };

    /**
     * Checks if the back button should be enabled for the given selected content view.
     * @param selectedContentView The current selected content view of the Asset Gallery Dialog Content.
     * @returns True if the back button should be enabled, false otherwise.
     */
    static checkIfBackButtonIsEnabled = (selectedContentView: AssetGalleryDialogContentViewType | undefined): boolean => {
        return selectedContentView === 'asset-editor';
    };

    /** Validate whether the type is accepted*/
    static typeIsAccepted = (needle: string | string[], haystack?: string | string[]): boolean => {
        if (Array.isArray(haystack)) {
            if (Array.isArray(needle)) {
                return needle.some((n) => haystack.indexOf(n) > -1);
            }

            return haystack.indexOf(needle) > -1;
        }

        if (Array.isArray(needle)) {
            if (!haystack) return false;
            return needle.indexOf(haystack) > -1;
        }

        return haystack?.toLowerCase() === needle.toLowerCase();
    };

    /**
     * Retrieves the filtered selector types based on the provided asset gallery dialog props.
     * @param props - AssetGalleryDialogProps.
     * @returns An array of AssetGalleryDialogSelectorType representing the filtered selector types.
     */
    static getFilteredSelectorsTypes = (props: AssetGalleryDialogProps): AssetGalleryDialogSelectorType[] => {
        return this.getSidebarSelectors(props).map((selector) => selector);
    };

    /**
     * Returns an array of filtered selectors based on the given parameters.
     * @param conditionProps - The condition props to filter by.
     * @param fileType - The type of file to filter by.
     * @param contentSpaceToken - The content space token to use.
     * @returns An array of filtered selectors.
     */
    static getFilteredSelectors = (
        selectorTypes: AssetGalleryDialogSelectorType[],
        conditionProps: AssetGalleryDialogState['conditionProps'],
        contentSpaceToken?: string,
        fileType?: string | string[]
    ): AssetGalleryDialogSelectorType[] => {
        const {
            canUpload,
            canUseUpload,
            canUseAiContent,
            canReuseImage,
            canUseUnsplash,
            canUseContentSpace,
            canUseMediaManagement,
            canUseAprimo,
            canUseUrl,
            canUseVoiceOverGenerator,
            canUseImageToVideo
        } = conditionProps;
        const selectorTypesCopy = [...selectorTypes];

        return selectorTypesCopy.filter((selector) => {
            switch (selector) {
                case 'reuseImage':
                    return this.typeIsAccepted([...IMAGE_EXTENSIONS, 'image'], fileType) && canReuseImage && ComponentStore.get('CreativeEditor');
                case 'unsplash':
                    return this.typeIsAccepted([...IMAGE_EXTENSIONS, 'image'], fileType) && canUseUnsplash;
                case 'upload':
                    return canUpload && canUseUpload;
                case 'aiContent':
                    return (
                        (canUseAiContent === true || canUseAiContent === undefined) &&
                        canUpload &&
                        this.typeIsAccepted([...IMAGE_EXTENSIONS, 'image'], fileType) &&
                        Setup.hasModule('AI')
                    );
                case 'contentSpace':
                    return canUseContentSpace && Setup.hasModule('assetLibrary') && User.hasRight('assetLibraryRead') && contentSpaceToken;
                case 'url':
                    return canUseUrl;
                case 'aprimo':
                    return canUseAprimo;
                case 'voiceOverGenerator':
                    return canUseVoiceOverGenerator && this.typeIsAccepted([...AUDIO_EXTENSIONS, 'audio'], fileType) && Setup.hasModule('AI');
                case 'imageToVideo':
                    return canUseImageToVideo && this.typeIsAccepted([...VIDEO_EXTENSIONS, 'video'], fileType) && Setup.hasModule('AI');
                case 'media':
                    return (
                        (canUseContentSpace || canUseMediaManagement) &&
                        Setup.hasModule('mediaManagement') &&
                        User.hasRight(['assetManagerMediaWrite', 'assetManagerMediaRead', 'assetManagerMediaManagement'])
                    );
                case 'frontify':
                    return (
                        Setup.hasModule('frontify') &&
                        (this.typeIsAccepted([...IMAGE_EXTENSIONS, 'image'], fileType) || this.typeIsAccepted([...VIDEO_EXTENSIONS, 'video'], fileType))
                    );
                default:
                    return true; // Keep any unknown selectors
            }
        });
    };

    /**
     * Either returns the selectors from the props or the default selectors.
     * @param props Asset gallery dialog props.
     * @returns a list of selectors.
     */
    static getSidebarSelectors = (props: AssetGalleryDialogProps): AssetGalleryDialogSelectorType[] => {
        const { contentSpaceToken, fileType } = props;
        const selectors = props.selectors;
        const conditionProps = AGDPropsToStateHelper.convertPropsToConditionProps(props);

        // If the selectors are provided in the props, return them.
        if (selectors && selectors.length > 0) {
            return this.getFilteredSelectors(selectors, conditionProps, contentSpaceToken, fileType);
        }

        // Return the default selectors.
        return this.getFilteredSelectors(defaultSelectorTypes, conditionProps, contentSpaceToken, fileType);
    };

    /**
     * The responsibility of this function is the get the current dialog title based on the selected content view and file type.
     * @param selectedContentView Selected content view.
     * @returns The current dialog title.
     */
    static getDialogTitle = (selectedContentView: AssetGalleryDialogContentViewType, defaultTitle: string): string => {
        const assetGalleryDialogState: AssetGalleryDialogState | undefined = ComponentStoreHelpers.get('AssetGalleryDialog');
        const extension = assetGalleryDialogState?.value.extension;
        const fileType = AssetHelper.getFileType(extension);

        switch (selectedContentView) {
            case 'asset-editor':
                // If the selected content view is the asset editor, we need to check if the asset is a video or image.
                return fileType === 'video' ? DIALOG_TITLES['video'] : DIALOG_TITLES['image'];
            case 'audio-trimmer':
                return DIALOG_TITLES['audio']; // Return the title of the audio trimmer.
            default:
                return defaultTitle; // Return the default title of the Asset Gallery Dialog.
        }
    };

    /**
     * Checks if the fixed height paper should have scroll paper based on the selected content view or the fixed height paper scroll paper prop, if it's defined.
     * @param selectedContentView The selected content view.
     * @returns True if the fixed height paper should have scroll paper, false otherwise.
     */
    static checkFixedHeightPaperScrollPaper = (selectedContentView: AssetGalleryDialogContentViewType, fixedHeightPaperScrollPaperProp?: boolean): boolean => {
        if (fixedHeightPaperScrollPaperProp === undefined) {
            return selectedContentView !== 'audio-trimmer' ? true : false;
        }

        return fixedHeightPaperScrollPaperProp; // Return the fixed height paper scroll paper prop if it's defined.
    };

    /**
     * Mode is deprecated for old templates but still used in some places. If mode is not set, use cropMode.
     * @param mode Old crop mode.
     * @param cropMode new crop mode
     * @returns the most recent crop mode.
     */
    static getUpdatedCropMode = (mode?: string, cropMode?: string): string | undefined => {
        // Mode is deprecated for old templates but still used in some places. If mode is not set, use cropMode.
        return mode ?? cropMode;
    };

    /**
     * If the user can use content space, fetch the token and set filterExpired to true.
     */
    static handleContentSpace = async (props: AssetGalleryDialogProps): Promise<void> => {
        const { canUseContentSpace, contentSpaceToken, filterExpired, setFilterExpired, fetchToken, ignoreFilterExpired } = props;
        if (canUseContentSpace) {
            if (!contentSpaceToken) await fetchToken();
            // If filterExpired is false, set it to true and reset the Redux store. We want to filter out expired assets in campaigns.
            if (!ignoreFilterExpired && !filterExpired) setFilterExpired(true);
        }
    };

    /**
     * Check if selectors are coming from the props or default and set them to the component store.
     */
    static handleSidebarSelectors = (props: AssetGalleryDialogProps): void => {
        const assetGalleryDialog: AssetGalleryDialogState | undefined = ComponentStoreHelpers.get('AssetGalleryDialog');

        const selectors = this.getSidebarSelectors(props);
        const prevSelectors = assetGalleryDialog?.components?.sidebar?.selectors;

        if (!isEqual(prevSelectors, selectors)) {
            ComponentStore.setModel('AssetGalleryDialog', 'components.sidebar.selectors', selectors);
        }
    };

    /**
     * Gets the content view that must be displayed when the dialog opens.
     */
    static getCurrentContentView = (props: AssetGalleryDialogProps, checkCropper = true): AssetGalleryDialogContentViewType => {
        // Set default view
        let currentView: AssetGalleryDialogContentViewType = 'sidebar';
        const fileType = AssetHelper.getFileType(props.value.extension);

        if (checkCropper) {
            if (props.value.openAssetEditor) {
                currentView = 'asset-editor';
            }

            // If image is already cropped, skip selector view and go to image cropper directly
            if (props.userCanCrop && fileType === 'image' && (props.value.crop || props.status === 'cropper')) {
                currentView = 'asset-editor';
            }

            if (props.status === 'cropper' && props.value && props.value.originalImage) {
                currentView = 'asset-editor';
            }

            if (props.userCanCrop && props.value && props.value.originalImage) {
                currentView = 'asset-editor';
            }

            // If video is already cropped, skip selector view and go to video cropper directly
            if (props.userCanCrop && props.value.isCropped && fileType === 'video') {
                currentView = 'asset-editor';
            }

            // Open video-cropper if there is an original video.
            if (fileType === 'video' && props.value.originalVideo) {
                currentView = 'asset-editor';
            }

            if (props.userCanTrim && props.value.isTrimmed && fileType === 'audio') {
                currentView = 'audio-trimmer';
            }
        }

        return currentView;
    };

    /**
     * Checks which content view must be displayed when the dialog opens and sets it to the component store.
     */
    static handleCurrentContentView = (props: AssetGalleryDialogProps): void => {
        const assetGalleryDialog: AssetGalleryDialogState | undefined = ComponentStore.get('AssetGalleryDialog');

        if (assetGalleryDialog) {
            const { selectedContentView } = assetGalleryDialog;
            const currentContentView = this.getCurrentContentView(props);

            // Only update the current content view if it has changed.
            if (selectedContentView !== currentContentView) {
                ComponentStore.setModel<AssetGalleryDialogState, 'selectedContentView'>('AssetGalleryDialog', 'selectedContentView', currentContentView);
            }
        }
    };

    /**
     * Handles the closing of the dialog.
     * @param onClose - Function to close the Asset Gallery Dialog.
     * @param [forceClose=false] - Whether to force the dialog to close even when "isProcessing" is true.
     */
    static handleDialogClose = (onClose: () => void, forceClose = false): void => {
        const assetGalleryDialog: AssetGalleryDialogState | undefined = ComponentStore.get('AssetGalleryDialog');

        if (!assetGalleryDialog?.isProcessing || forceClose) {
            onClose(); // Close the asset gallery dialog.
        } else {
            this.setIsWarningDialogOpen(true); // Open the warning dialog.
        }
    };

    /**
     * Changes the state of the asset gallery dialog processing state.
     * @param isProcessing - Whether the dialog is processing, old components uses other types than boolean.
     */
    static handleProcessing = (isProcessing: any): void => {
        const assetGalleryDialog: AssetGalleryDialogState | undefined = ComponentStore.get('AssetGalleryDialog');

        if (assetGalleryDialog) {
            ComponentStore.setModel<AssetGalleryDialogState, 'isProcessing'>('AssetGalleryDialog', 'isProcessing', isProcessing);
        }
    };

    /**
     * Opens or closes the warning dialog.
     */
    static setIsWarningDialogOpen = (isDialogOpen: boolean): void => {
        ComponentStore.setModel<AssetGalleryDialogState, 'components.warningDialog.isDialogOpen'>(
            'AssetGalleryDialog',
            'components.warningDialog.isDialogOpen',
            isDialogOpen
        );
    };

    /**
     * Closes the warning dialog and the asset gallery dialog.
     */
    static handleWarningDialogConfirm = (onClose: () => void): void => {
        this.setIsWarningDialogOpen(true); // Close the warning dialog.
        this.handleDialogClose(onClose, true); // Close the asset gallery dialog.
    };

    /**
     * This function will always make sure that the props contains the update crop mode.
     */
    static getUpdatedProps = (props: AssetGalleryDialogProps, mode?: string, cropMode?: string): AssetGalleryDialogProps => {
        const updatedCropMode = this.getUpdatedCropMode(mode, cropMode);

        // The new props that contains the changes, including the updated cropMode.
        return {
            ...props,
            mode: updatedCropMode,
            cropMode: updatedCropMode
        };
    };

    /**
     * Change the array to a string if it only contains one element and the element is 'video'.
     * This function is needed because the AssetGalleryDialog only supports a video string type.
     */
    static convertSingleFiletypeArrayToString = (fileTypes?: FileType | FileType[]): FileType | FileType[] | undefined => {
        if (Array.isArray(fileTypes) && fileTypes.length === 1) {
            const [fileType] = fileTypes;
            if (['video'].includes(fileType)) return fileType; // Change the array to a string if it only contains one element and the element is 'video'.
        }
        return fileTypes;
    };
}

export default AssetGalleryDialogHelper;
